import * as React from 'react';
import './style.scss';
import Text from '../Text';
import Button from '../Button';
import classNames from 'classnames';

interface Props {
  title: string;
  buttonTitle: string;
}

const HaveIdea: React.FC<Props> = ({ title, buttonTitle }) => {
  return (
    <div className={'have-idea'}>
      <Text
        type="h2"
        color="light"
        weight="demi-bold"
        className={classNames('have-idea__title', 'h4')}
        align="center"
      >
        {title}
      </Text>
      <Button
        color="light"
        size="medium"
        className={'have-idea__button'}
        href="/contact-us/"
      >
        <Text
          className={'have-idea__button-text'}
          color="secondary"
          weight="demi-bold"
          type="button"
        >
          {buttonTitle}
        </Text>
      </Button>
    </div>
  );
};

export default HaveIdea;
