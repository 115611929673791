import * as React from 'react';

const ClientTeamGraph = () => (
  <svg width={402} height={202} viewBox="0 0 402 202">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M221 .5h-40"
        stroke="#CFD3DE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray={6}
      />
      <g transform="translate(197.5 1)">
        <path
          d="M87.75 0v3c0 23.196-18.804 42-42 42H42C18.804 45 0 63.804 0 87v3h0"
          stroke="#CFD3DE"
          strokeWidth={2}
          strokeLinecap="round"
          strokeDasharray={6}
        />
        <rect
          fill="#CFD3DE"
          transform="rotate(-45 46 44)"
          x={37}
          y={35}
          width={18}
          height={18}
          rx={4}
        />
      </g>
      <g
        stroke="#CFD3DE"
        strokeDasharray={6}
        strokeLinecap="round"
        strokeWidth={2}
      >
        <path d="M199 99v11.5c0 18.502-14.998 33.5-33.5 33.5-18.502 0-33.5 14.998-33.5 33.5V189h0M199 99v11.5c0 18.502 14.998 33.5 33.5 33.5 18.502 0 33.5 14.998 33.5 33.5V189h0" />
      </g>
      <circle fill="#CFD3DE" cx={50.5} cy={98} r={6} />
      <circle fill="#CFD3DE" cx={395} cy={152} r={6} />
      <path
        d="M197.5 98h-173c-13.255 0-24 10.745-24 24v78h0M197.5 98h173c13.255 0 24 10.745 24 24v78h0"
        stroke="#CFD3DE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray={6}
      />
    </g>
  </svg>
);

export default ClientTeamGraph;
