import { useState, useEffect } from 'react';
import posts from '../components/PostsList/api';

const usePosts = () => {
  const [fetchedPosts, setPosts] = useState([]);

  useEffect(() => {
    posts().then(({ posts }) => setPosts(posts));
  }, []);

  return fetchedPosts;
};

export default usePosts;
