import classNames from 'classnames';
import React, { useCallback } from 'react';
import Text from '../Text';
import './postsBlock.scss';
import './postsList.scss';
import './postTags.scss';
import './style.scss';
import Button from '../Button';
import Link from '../Link';
import Category from '../Category';
import usePosts from '../../hooks/usePosts';
import LazyLoad, { lazyload } from 'react-lazyload';
import GatsbySvgImage from '../GatsbySvgImage';

interface Tag {
  name: string;
  slug: 'development' | 'tech' | 'case-study' | 'product-strategy';
}
type TPost = {
  title: string;
  image: any;
  time: string;
  url: string;
  excerpt?: string
  tags: Tag[]
}
interface Props {
  noBottomPadding?: boolean;
  className?: string;
  title?: string,
  btnHide?: boolean,
  category?: string,
  descriptionHide?: boolean
  titleAligned?: "left" | "center" | "right"
  postList?: TPost
}

const PostsList: React.FC<Props> = ({ 
  noBottomPadding = false, 
  className, 
  btnHide, 
  titleAligned, 
  descriptionHide,
  title = 'Inspire yourself from our Blog',
  postList
}) => {
  const posts = (postList ? postList : usePosts()) as TPost[];
  const renderTag = useCallback<(item: Tag) => React.ReactNode>(item => {
    const tagStyle =
      item.slug === 'case-study'
        ? 'orange'
        : item.slug === 'development'
        ? 'green'
        : 'grey';

    return (
      <Category
        style={tagStyle}
        key={item.slug}
        isCapital
        href={`https://stormotion.io/blog/tag/${item.slug}/`}
      >
        {item.name}
      </Category>
    );
  }, []);

  return (
    <div
      className={classNames(className, 'posts', {
        ['posts--no-bottom-padding']: noBottomPadding,
      })}
    >
      <div className={'posts__container'}>
        <div className={`posts__container__header ${titleAligned ?? ""}`}>
          <Text
            type="h2"
            color="dark"
            weight="bold"
            align={titleAligned}
            className={'posts__container__header__title h3'}
          >
            {title}
          </Text>
          {
            !btnHide && ( <Link
              href={'https://stormotion.io/blog/'}
              additionalClassName={'posts__container__header__button'}
            >
              <Button color="transparent" size="small" outlined>
                All Blog Posts
              </Button>
            </Link>)
          }
         
        </div>
        <div className={'posts-block'}>
          {posts?.map((post, index: number) => {
            return (
              <Link
                href={'https://stormotion.io/blog' + post.url}
                key={`${post.title}-${index}`}
                additionalClassName={'post-tile'}
              >
                <div className="post-tile__heading">
                  {typeof post.image !== "string" ? (<GatsbySvgImage  
                      image={post.image}
                      classNames={'post-tile__image'}
                      alt={post.title}
                  /> ):(<img
                    src={post.image}
                    className={'post-tile__image'}
                    alt={post.title}
                  />)}
                  <Text
                    type="p"
                    color="dark"
                    weight="demi-bold"
                    className={'post-tile__title'}
                    linkClassName="dark-mode h5"
                  >
                    {post.title}
                  </Text>
                </div>
                <div className="post-tile__description">
                {
                  !descriptionHide && (
                    <Text
                      type="label"
                      color="dark"
                      weight="medium"
                      dim="60"
                      className={'post-tile__text'}
                    >
                      {post.excerpt}
                    </Text>
                  )
                }
                <div className="post-tile__footer">
                  {/* we want to have only 1 tag */}
                  {post.tags && post.tags.slice(0, 1).map(renderTag)}
                  <Text
                    className="post-tile__time"
                    color="dark"
                    type="caption"
                  >
                    {post.time} 
                  </Text>
                </div>
              </div>
       
              </Link>
            );
          })}
        </div>
        <Link
          href={'https://stormotion.io/blog/'}
          additionalClassName={'posts__container__button'}
        >
          <Button
            color="transparent"
            size="big"
            outlined
            className={'posts__container__button__content'}
          >
            All Blog Posts
          </Button>
        </Link>
      </div>
    </div>
  );
};

const LazyPostsList: React.FC<Props> = props => (
  <LazyLoad offset={800} once>
    <PostsList {...props} />
  </LazyLoad>
);

export default LazyPostsList;
