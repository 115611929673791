import { HeadProps, PageProps, graphql } from 'gatsby';
import React, { useMemo } from 'react';
import BuiltByStormotion from '../components/BuiltByStormotion';
import Comments from '../components/Comments';
import DigitalProductDevelopment from '../components/DigitalProductDevelopment';
import Features from '../components/Features';
import Footer from '../components/Footer';
import HaveIdea from '../components/HaveIdea';
import HeaderHero from '../components/HeaderHero';
import LatestArticlesList from '../components/LatestArticlesList';
import Layout from '../components/layout';
import LinkOutreach from '../components/LinkOutreach';
import Navigation from '../components/Navigation';
import PostsList from '../components/PostsList';
import ProductDev from '../components/ProductDev';
import Questions from '../components/Questions';
import SEO from '../components/seo';
import Special from '../components/Special';
import StaffAugmentation from '../components/StaffAugmentation';
import Tehnology from '../components/Tehnology';
import useApps from '../hooks/useApps';
import './style.scss';
import TabItemLegacy from '../components/LinkOutreach/TabItemLegacy';

type DataProps = any;

function ProjectTemplateLegacy({ data }: PageProps<DataProps>) {
  const { mdx } = data;

  const {
    frontmatter: {
      question,
      header,
      tabBlock,
      features,
      posts,
      special,
      contactUs,
      showFooter,
      traits,
      buildBy,
      comments,
      development,
      technologiBlock,
    },
  } = mdx;

  const apps = useApps(true);

  const dataConvertForTab = useMemo(
    () =>
      tabBlock?.tabs?.map((t) => ({
        ...t,
        description: <TabItemLegacy data={t.description} />,
      })),
    [tabBlock]
  );
  return (
    <Layout>
      <Navigation headerBackgroundColor="none" />
      <div className="project">
        <HeaderHero
          list={header.items}
          btnTitle={header.buttonTitle}
          btnHref={header.buttonHref}
          title={header.title}
          bgImage={header.bgImage}
        />
        <Special traits={special.items} title={special.title} />
        <PostsList
          btnHide
          title={posts.title}
          titleAligned="center"
          descriptionHide
          postList={posts?.items?.map((p) => ({
            ...p,
            tags: p?.tags?.map((t) => ({ slug: t?.toLowerCase(), name: t })),
          }))}
        />
        <LinkOutreach
          backgroundMode="darkBG"
          variant="primary"
          colorMode={'light'}
          tabs={dataConvertForTab}
          header={tabBlock.header}
        />
        <Features features={features.features} header={features.header} />
        <Tehnology
          tabs={technologiBlock.tabs}
          header={technologiBlock.header}
          details={technologiBlock.details}
        />
      </div>
      <HaveIdea title={contactUs.title} buttonTitle={contactUs.buttonTitle} />
      <BuiltByStormotion apps={apps} title={buildBy?.title} />
      <Comments title={comments?.title} />
      {development?.productDevelopment && development?.staffAugmentation ? (
        <ProductDev {...development} />
      ) : development?.productDevelopment ? (
        <DigitalProductDevelopment {...development?.productDevelopment} />
      ) : (
        <StaffAugmentation
          {...development?.staffAugmentation}
          title="Staff Augmentation"
        />
      )}
      <Special traits={traits} title={traits?.title} />
      <LatestArticlesList />
      <Questions
        questions={question?.items ?? []}
        title={question?.title}
        subTitle={question?.subTitle}
      />
      {showFooter && <Footer noFooterCard={false} />}
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        header {
          title
          items
          buttonTitle
          buttonHref
          bgImage {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 1920)
            }
            extension
            publicURL
          }
        }
        comments {
          title
        }
        buildBy {
          title
        }
        special {
          title
          items {
            title
            icon {
              childImageSharp {
                gatsbyImageData(
                  height: 44
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              extension
              publicURL
            }
            description
          }
        }
        posts {
          title
          slug
          items {
            title
            time
            url
            tags
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        technologiBlock {
          header
          details {
            title
            description
          }
          tabs {
            description {
              code
            }
            header
          }
        }

        features {
          header
          features {
            title
            icon {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              extension
              publicURL
            }
          }
        }
        tabBlock {
          header
          tabs {
            header
            description {
              title
              imageSign
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                extension
                publicURL
              }
              description
              btnText
              btnHref
              list {
                icon {
                  childImageSharp {
                    gatsbyImageData(
                      height: 44
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                  extension
                  publicURL
                }
                text
              }
            }
          }
        }
        development {
          productDevelopment {
            header
            title
            description
            steps
          }
          staffAugmentation {
            header
            patterns {
              title
              description
            }
          }
        }
        specialTitle
        traits {
          title
          description
          icon {
            childImageSharp {
              gatsbyImageData(
                height: 44
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            extension
            publicURL
          }
        }
        contactUs {
          title
          buttonTitle
        }
        question {
          title
          subTitle
          items {
            question
            answer
          }
        }
        showFooter
      }
    }
  }
`;

export const Head = ({ data }: HeadProps<DataProps>) => {
  const { mdx } = data;

  const {
    frontmatter: { title, description, question },
  } = mdx;

  return (
    <SEO title={title} description={description} questions={question?.items} />
  );
};

export default ProjectTemplateLegacy;
