import classNames from 'classnames';
import * as React from 'react';
import { StageType } from '../Stages';
import Text from '../Text';
import RockSolidGraph from '../icons/RockSolidGraph';
import './style.scss';

interface Props {
  title: string;
  description: string;
  steps: string[];
  header?: string;
}

const graphItems: StageType[] = [
  {
    title: 'Pre-Project',
    items: [
      {
        text: 'Discovery Stage',
        color: 'yellow',
      },
      {
        text: 'Planing',
        color: 'green',
      },
    ],
  },
  {
    title: 'Agile Development',
    items: [
      {
        text: 'UX-Prototyping',
        color: 'orange',
      },
      {
        text: 'Design',
        color: 'yellow',
      },
      {
        text: 'Development',
        color: 'blue',
      },
      {
        text: 'QA & Testing',
        color: 'green',
      },
    ],
  },
  {
    title: 'Transition',
    items: [
      {
        text: 'Maintenance',
        color: 'yellow',
      },
      {
        text: 'Handover',
        color: 'orange',
      },
      {
        text: 'Next Iteration',
        color: 'red',
      },
    ],
  },
];

const DigitalProductDevelopment: React.FC<Props> = ({
  title,
  description,
  steps,
  header,
}) => {
  const [col, setCol] = React.useState('1fr 1fr 1fr');
  React.useEffect(() => {
    steps.length >= 2 ? setCol('1fr 1fr') : setCol('1fr');
  }, [steps]);
  return (
    <div className="product-dev__wrapper">
      {header && (
        <Text
          type="h3"
          color="light"
          weight="bold"
          className={'product-dev__header'}
        >
          {header}
        </Text>
      )}
      <Text
        type="p"
        color="light"
        weight="medium"
        className={'product-dev__description'}
      >
        {title}
      </Text>
      <div className={'product-dev__graph'}>
        {graphItems?.map((item, index) => {
          return (
            <div
              key={`product-dev-graph-items-${item.title}-${index}`}
              className={classNames('product-dev__graph__container')}
            >
              <Text
                color="alternative2"
                type="h3"
                weight="demi-bold"
                className={'product-dev__graph__container__title'}
              >
                {item.title}
              </Text>
              <div className={'product-dev__graph__container__tags'}>
                {item.items?.map((tag, index) => {
                  return (
                    <Text
                      key={`product-dev-graph-item-item-${tag.text}-${index}`}
                      color="light"
                      type="label"
                      weight="demi-bold"
                      className={classNames(
                        'product-dev__graph__container__tags__tag',
                        `product-dev__graph__container__tags__tag--${tag.color}`
                      )}
                    >
                      {tag.text}
                    </Text>
                  );
                })}
              </div>
              {index < 2 && (
                <RockSolidGraph
                  lightMode
                  className={classNames(
                    'product-dev__graph__container__svg',
                    `product-dev__graph__container__svg--${index}`
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
      <Text
        type="p"
        color="light"
        align="center"
        className={'product-dev__graph__description'}
      >
        {description}
      </Text>
      <Text
        type="p"
        color="light"
        weight="demi-bold"
        className={'product-dev__steps__title'}
      >
        It could also include such steps:
      </Text>
      <div className={'product-dev__steps'}>
        <ul
          style={{
            gridTemplateColumns: col,
          }}
        >
          {steps?.map((step, index) => (
            <li key={`product-dev-steps-${step}-${index}`}>
              <Text type="p" color="light" weight="medium" className="h5">
                {step}
              </Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DigitalProductDevelopment;
