import * as React from "react"
import colors from "../../theme/colors"

interface Props {
  className: string
  lightMode?: boolean
}

const RockSolidGraph: React.SFC<Props> = ({ className, lightMode }) => (
  <svg width={191} height={40} className={className} viewBox="0 0 191 40">
    <g
      transform="translate(2 2)"
      stroke={lightMode ? "#FFF" : colors.main.textColor.ALTERNATIVE2}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M0 0c20.667 19.318 52 28.977 94 28.977S167.333 19.317 188 0"
        strokeLinecap="round"
        strokeDasharray={8}
      />
      <rect
        fill={lightMode ? "#29D5AC" : colors.main.backgroundColor.PRIMARY}
        transform="rotate(-45 94 29)"
        x={88.5}
        y={23.5}
        width={11}
        height={11}
        rx={4}
      />
    </g>
  </svg>
)

export default RockSolidGraph
