import * as React from 'react';
import './style.scss';
import Text from '../Text';
import LinkOutreach from '../LinkOutreach';
import { Tab } from '../../models/Tab';
import CodeSnippet from '../CodeSnippet';
import { useCallback, useMemo } from 'react';

type TList = {
  title: string;
  description: string;
};
interface ITechnology {
  tabs: Tab[];
  header: string;
  details: TList[];
}

const Tehnology: React.FC<ITechnology> = ({ tabs, header, details }) => {
  const renderTab = useCallback(
    (tab) => ({
      ...tab,
      description: (
        <CodeSnippet
          highlighterProps={{
            useInlineStyles: false,
          }}
          code={tab.description.code}
        />
      ),
    }),
    []
  );

  const codeTabs = useMemo(() => tabs?.map(renderTab), [tabs, renderTab]);

  return (
    <div className="technology-legacy__details">
      <div className="technology-legacy__details__container">
        <Text
          color="dark"
          type="h2"
          weight="bold"
          className={'technology-legacy__details__title'}
        >
          {header}
        </Text>
      </div>
      <div className="technology-legacy__details__container technology-legacy__details__container--link">
        <div className="technology-legacy__details__col">
          <div className="technology-legacy__details__card">
            <LinkOutreach
              variant="secondary"
              colorMode={'dark'}
              tabs={codeTabs}
            />
          </div>
        </div>
        <div className="technology-legacy__details__col">
          <ul className="technology-legacy__details__list">
            {details?.map((d, i) => {
              return (
                <li className="technology-legacy__details__list__item" key={i}>
                  <Text
                    color="dark"
                    type="h3"
                    weight="bold"
                    className={'link-outreach__content__title'}
                  >
                    {d.title}:
                  </Text>
                  <p>{d.description}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Tehnology);
