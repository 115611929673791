import { IGatsbyImageData, getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import GatsbySvgImage from '../GatsbySvgImage';
import Text from '../Text';
import './style.scss';

interface IFeatures {
  features: {
    icon: IGatsbyImageData;
    title: string;
  }[];
  header: string;
}
const Features: React.FC<IFeatures> = ({ features, header }) => {
  return (
    <div className="dev__features">
      <Text
        color="dark"
        type="h2"
        weight="bold"
        align="center"
        className={'dev__features__title'}
      >
        {header}
      </Text>
      <div className="dev__features__container">
        {features?.map((feature, i) => {
          return (
            <div className="dev__features__item" key={i}>
              <GatsbySvgImage image={feature.icon} alt={feature.title} />
              <Text type="h3" color="dark" weight="bold">
                {feature.title}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
