import classNames from 'classnames';
import * as React from 'react';
import StandaloneGraph from '../icons/StandaloneGraph';
import Text from '../Text';
import './style.scss';

const GraphStandalone: React.SFC = () => {
  return (
    <div className={'graph'}>
      <StandaloneGraph />
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-small',
          'graph__container--white',
          'graph__container--top-240-0',
          'graph__container--left-488-88'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="bold"
          className={'text'}
        >
          Client
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-small',
          'graph__container--secondary',
          'graph__container--top-240-0',
          'graph__container--right-488-88'
        )}
      >
        <Text type="label" color="light" weight="bold" className={'text'}>
          Stormotion
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-medium',
          'graph__container--white',
          'graph__container--top-240-99',
          'graph__container--left-488-166'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="bold"
          className={'text'}
        >
          Product Owner
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-small',
          'graph__container--primary',
          'graph__container--bottom-240-0',
          'graph__container--left-488-89'
        )}
      >
        <Text type="label" color="light" weight="bold" className={'text'}>
          SM Dev
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-small',
          'graph__container--primary',
          'graph__container--bottom-240-0',
          'graph__container--left-488-176'
        )}
      >
        <Text type="label" color="light" weight="bold" className={'text'}>
          SM Dev
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-240-small',
          'graph__container--width-488-small',
          'graph__container--white',
          'graph__container--bottom-240-0',
          'graph__container--right-488-89'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="bold"
          className={'text'}
        >
          Client
        </Text>
      </div>
    </div>
  );
};

export default GraphStandalone;
