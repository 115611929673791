import fetch from 'isomorphic-unfetch';

const apiUrl = 'https://stormotion.io/blog/ghost/api/v0.1/';

const clientId = 'ghost-frontend';
const clientSecret = 'bba4d69c3fc7';

function getReadingTimeString(text: string) {
  const wordCount = text
    .toString()
    .replace(/[^\w ]/g, '')
    .split(/\s+/).length;
  const readingTimeInMinutes = Math.floor(wordCount / 280) + 1;
  return readingTimeInMinutes + ' min read';
}

function stripHtml(html: string) {
  return html.replace(/<[^>]+>/g, '');
}

export default async function(): Promise<{ posts: any }> {
  const params = {
    limit: '3',
    include: 'tags',
    client_id: clientId,
    client_secret: clientSecret,
  };
  const url = new URL(`${apiUrl}posts?${new URLSearchParams(params)}`);

  const init = { method: 'GET' };

  try {
    console.warn(`URL - ${url.toString()}`);
    const res = await fetch(url.toString(), init);

    const json = await res.json();

    const posts = json.posts?.map(function(post: any) {
      // const text = fromString(post.html, {
      //   wordwrap: false,
      //   ignoreHref: true,
      //   ignoreImage: true,
      //   uppercaseHeadings: false,
      //   singleNewLineParagraphs: true,
      // });
      const text = stripHtml(post.html);

      return {
        image: `https://stormotion.io${post.feature_image}`,
        url: post.url,
        title: post.title,
        tags: post.tags,
        time: getReadingTimeString(text),
        excerpt: post.meta_description,
      };
    });

    return { posts };
  } catch (err) {
    console.error(err);
    return { posts: [] };
  }
}
