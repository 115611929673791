import * as React from 'react';
import './style.scss';
import HeaderHeroLegacy from './HeaderHeroLegacy';
import HeaderHeroUpdated from './HeaderHeroUpdated';
export interface IHero {
  title?: string;
  description?: string;
  list: string[];
  btnTitle?: string;
  btnHref?: string;
  bgImage?: any;
  isLegacy?: boolean;
}

const HeaderHero = ({ isLegacy = true, ...props }: IHero) =>
  isLegacy ? <HeaderHeroLegacy {...props} /> : <HeaderHeroUpdated {...props} />;

export default HeaderHero;
