import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Check from '../../components/icons/Check';
import Button from '../Button';
import Text from '../Text';

export interface IHero {
  title?: string;
  list: string[];
  btnTitle?: string;
  btnHref?: string;
  bgImage?: any;
}

const HeaderHero = ({ list, title, btnTitle, btnHref, bgImage }: IHero) => {
  const [isLoaded, setLoaded] = useState(false);

  const Content = useMemo(
    () => (
      <div className="header__hero-legacy">
        <div className="header__hero-legacy__wrapper">
          <Text
            type="h1"
            weight="bold"
            color="light"
            className="header__hero-legacy__title--main h2"
          >
            {title}
          </Text>
          <ul className="header__hero-legacy__list">
            {list.map((l, i) => (
              <li key={i} className="header__hero-legacy__list-item">
                <Check />
                {l}
              </li>
            ))}
          </ul>
          <Button
            size="big"
            href={btnHref}
            outlined
            outerLink
            color="light"
            className={'header__hero-legacy__action__btn'}
            darkMode
          >
            {btnTitle}
          </Button>
        </div>
        <div className="header__hero-legacy__details">
          <div className="header__hero-legacy__flag">
            <Text type="p" color="light">
              5/5 Stars
            </Text>
            <Link
              to="https://clutch.co/profile/stormotion"
              rel="nofollow noopener"
            >
              <StaticImage
                src="../../images/clutch.png"
                alt="highly recommended on clutch"
                placeholder="blurred"
                className={'header__hero-legacy__image-logos'}
              />
            </Link>
          </div>
          <div className="header__hero-legacy__flag">
            <Text type="p" color="light">
              Top Rated
            </Text>
            <StaticImage
              src="../../images/upwork.png"
              placeholder="blurred"
              alt="best upwork company"
              className={'header__hero-legacy__image-logos'}
            />
          </div>
        </div>
      </div>
    ),
    [list, title, btnTitle, btnHref]
  );

  useEffect(() => {
    setTimeout(() => setLoaded(true), 3200);
  }, []);

  return (
    <div
      className={`${
        !isLoaded && 'header__hero-legacy__image__bg--empty'
      } header__hero-legacy__image__bg`}
    >
      {isLoaded ? (
        <BackgroundImage
          Tag="div"
          className="header__hero-legacy__image__bg__wrapper"
          {...convertToBgImage(getImage(bgImage))}
        >
          {Content}
        </BackgroundImage>
      ) : (
        Content
      )}
    </div>
  );
};

export default HeaderHero;
