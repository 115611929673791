import * as React from 'react';
import './style.scss';
import { Tab } from '../../models/Tab';
import TehnologyLegacy from './TehnologyLegacy';
import TehnologyUpdated from './TehnologyUpdated';

type TList = {
  title: string;
  description: string;
};
interface ITechnology {
  tabs: Tab[];
  header: string;
  details: TList[];
  isLegacy?: boolean;
}

const Tehnology: React.FC<ITechnology> = ({ isLegacy = true, ...props }) => {
  return isLegacy ? (
    <TehnologyLegacy {...props} />
  ) : (
    <TehnologyUpdated {...props} />
  );
};

export default React.memo(Tehnology);
