import React from 'react';
import GatsbySvgImage from '../GatsbySvgImage';
import Link from '../Link';
import Text from '../Text';

type TList = {
  icon: any;
  text: string;
};

interface ITabItem {
  data: {
    image: any;
    imageSign?: string;
    title: string;
    description: string;
    btnText?: string;
    list: TList[];
    btnHref: string;
  };
}
const TabItem: React.FC<ITabItem> = ({ data: { ...props } }) => {
  return (
    <div className="link-outreach-legacy__tab__container">
      <div className="link-outreach-legacy__tab__col">
        <div className="link-outreach-legacy__tab__image__container">
          <GatsbySvgImage image={props.image} alt={props.title} />
        </div>
        <Text color="light" dim="40" type="p">
          {props.imageSign ?? ''}
        </Text>
      </div>
      <div className="link-outreach-legacy__tab__col">
        <Text color="light" type="h3" className="h2" weight="bold">
          {props.title}
        </Text>
        <Text color="light" type="p">
          {props.description}
        </Text>
        <div className="link-outreach-legacy__tab__list">
          {props.list.map((l, i) => {
            return (
              <div className="link-outreach-legacy__tab__list__item" key={i}>
                <GatsbySvgImage image={l.icon} alt={l.text} />
                <Text color="light" type="p">
                  {l.text}
                </Text>
              </div>
            );
          })}
        </div>
        {props.btnHref && (
          <Link href={props.btnHref}>Read Also: {props.btnText ?? ''}</Link>
        )}
      </div>
    </div>
  );
};

export default TabItem;
