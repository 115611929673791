import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import DigitalProductDevelopment from '../DigitalProductDevelopment';
import GraphClientTeam from '../Graphs/GraphClientTeam';
import GraphStandalone from '../Graphs/GraphStandalone';
import StaffAugmentation from '../StaffAugmentation';
import Text from '../Text';
import './style.scss';

const steps = [
  'Brainstorming for defining a scalable Tech-Stack',
  'Landing Page for Early Customer Acquisition',
  'Customer Development with Potential Clients',
  'Interim-CTO Position for Investor Pitching',
];

const patterns = [
  {
    graph: <GraphStandalone />,
    title: 'Standalone SM Team',
    description: 'A managed full-stack Scrum team (FrontEnd, BackEnd, PM, QA).',
    caption:
      'Developing a new Web or Mobile Product for your business from scratch, based on your specifications.',
  },
  {
    graph: <GraphClientTeam />,
    title: 'Client Team + SM Team',
    description:
      'One or several resources (e.g. a React Native Dev) to work side-by-side with your PM / Product Owner & Existing Development Team.',
    caption:
      "Filling the gap in your Tech-Team, while you're looking for a candidate locally. Development of a Mobile App for an existing Web-Product if no mobile developers In-House.",
  },
];

interface Props {
  productDevelopment: {
    header: string;
    title: string;
    description: string;
    steps: string[];
  };
  staffAugmentation: {
    header: string;
    patterns: {
      title: string;
      description: string;
      caption: string;
    }[];
  };
}

const ProductDev: React.FC<Props> = ({
  productDevelopment,
  staffAugmentation,
}) => {
  // make default value undefined, so that google cache shows all the options opened
  const [isProductDevelopment, setProductDevelopment] = useState<boolean>();

  useEffect(() => {
    setProductDevelopment(true);
  }, []);

  const onChooseProductDevelopment = useCallback(
    () => setProductDevelopment(true),
    []
  );
  const onChooseOutstaff = useCallback(() => setProductDevelopment(false), []);

  return (
    <div
      className={classNames('product-dev', {
        'product-dev--product-development': isProductDevelopment,
      })}
    >
      <div className={'product-dev-tabs'}>
        <div role="button" onClick={onChooseProductDevelopment}>
          <Text
            type="h2"
            color="light"
            weight="demi-bold"
            align="center"
            className={classNames('product-dev__title', {
              'product-dev__title--active': isProductDevelopment,
              'product-dev__title--product-development': isProductDevelopment,
              'product-dev__title--product-development--active':
                isProductDevelopment,
            })}
          >
            {productDevelopment.header}
          </Text>
        </div>
        <div role="button" onClick={onChooseOutstaff}>
          <Text
            type="h2"
            color="light"
            weight="demi-bold"
            align="center"
            className={classNames('product-dev__title', {
              'product-dev__title--active': !isProductDevelopment,
              'product-dev__title--product-development': isProductDevelopment,
            })}
          >
            {staffAugmentation.header}
          </Text>
        </div>
      </div>
      {isProductDevelopment === undefined ? (
        <>
          <DigitalProductDevelopment
            steps={productDevelopment.steps}
            title={productDevelopment.title}
            description={productDevelopment.description}
          />
          <StaffAugmentation patterns={staffAugmentation.patterns} />
        </>
      ) : isProductDevelopment ? (
        <DigitalProductDevelopment
          steps={productDevelopment.steps}
          title={productDevelopment.title}
          description={productDevelopment.description}
        />
      ) : (
        <StaffAugmentation patterns={staffAugmentation.patterns} />
      )}
    </div>
  );
};

export default ProductDev;
