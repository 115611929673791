import React, { useCallback, useMemo } from 'react';
import GraphClientTeam from '../Graphs/GraphClientTeam';
import GraphStandalone from '../Graphs/GraphStandalone';
import Text from '../Text';
import './style.scss';

interface Pattern {
  title: string;
  description: string;
  caption?: string;
}

interface GraphPattern extends Pattern {
  graph: JSX.Element;
}

interface Props {
  patterns: Pattern[];
  title?: string;
}

const graphs = [<GraphStandalone />, <GraphClientTeam />];

const StaffAugmentation: React.FC<Props> = ({ patterns, title }) => {
  const patternsWithGraph = useMemo(
    () =>
      patterns?.map((pattern, index) => ({
        ...pattern,
        graph: graphs[index],
      })),
    [patterns]
  );
  const renderPattern = useCallback<
    (item: GraphPattern, index: number) => JSX.Element
  >((pattern, index) => {
    return (
      <div
        key={`dev-match-${pattern.title}-${index}`}
        className={'dev-match__patterns__pattern'}
      >
        <div className={'dev-match__patterns__pattern__graph'}>
          {pattern.graph}
        </div>
        <div className={'dev-match__patterns__pattern__content'}>
          <Text
            type="h4"
            color="dark"
            weight="demi-bold"
            className={'dev-match__patterns__pattern__content__title'}
          >
            {pattern.title}
          </Text>
          <Text
            type="h6"
            color="dark"
            weight="medium"
            className={'dev-match__patterns__pattern__content__description'}
          >
            {pattern.description}
          </Text>
          {pattern.caption && (
            <Text
              type="h6"
              color="dark"
              className={'dev-match__patterns__pattern__content__caption'}
            >
              <span>Typical Usecase: </span>
              {pattern.caption}
            </Text>
          )}
        </div>
      </div>
    );
  }, []);

  return (
    <div className={'dev-match__patterns'}>
      {title && (
        <Text
          className={'dev-match__patterns__title'}
          type="h3"
          weight="bold"
          color="dark"
        >
          {title}
        </Text>
      )}
      <div className={'dev-match__patterns__wrapper'}>
        {patternsWithGraph?.map(renderPattern)}
      </div>
    </div>
  );
};

export default StaffAugmentation;
